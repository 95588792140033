Vue.component('loginPage', {
    props:['register'],
    watch:{
        "$store.getters.getIsLogin":'checkSession',
        "username":function(){this.message=''},
        "password":function(){this.message=''},
        "beforeLogin":function(){this.message=''},
    },
    data:function(){
        return {
            message:null,
            username:'',
            password:'',
            beforeLogin:false
        }
    },
    computed:{
        redirectUrl:function(){
            console.log(window.atob(this.$route.query.redirect));
            if(this.$route.query.redirect)
                return window.atob(this.$route.query.redirect);
            else
                return '/ecommerce/#/home'
        },
    },
    methods:{
        clear(){
            this.message='';
            this.username='';
            this.password='';
            this.beforeLogin = false;
        },
        checkSession(){
            if (this.$store.getters.getIsLogin) {
                this.clear();
                window.location.href=this.redirectUrl;
            }
        },
        async login(){
            this.beforeLogin=true;
            let loginResult = await this.$store.dispatch('login',{username:this.username,password:this.password});
            if(loginResult.ok==false)
                this.message = this.tr("Incorrect Username or Password");
            if(this.$store.state.mustUpdateInfo==true)
                this.router.push('/mustUpdateInfo');
            if (this.$store.getters.getIsLogin) {
                this.clear();
                alert(this.redirectUrl);
                //window.location.href=this.redirectUrl;
            }
            else
                this.message = this.tr("Incorrect Username or Password");

        },
        closePage:function(){
            this.$router.go("home");
        }
    },
    template: `<div class="container">
                    <headerSectionComponent></headerSectionComponent>
                    <div class="card">
                          <div class="card-content">
                              <h5 class="card-title center-align">{{tr('Login')}}</h5>
                              <div class="row">
                                  <div class="col s6 offset-s3 center-align">
                                            <div class="input-field">
                                                <input :placeholder="tr('User')" v-model="username" name="username" type="text" class="validate">
                                            </div>
                                            <div class="input-field">
                                                <input :placeholder="tr('Password')" v-model="password"  id="password" name="password" type="password" class="validate">
                                            </div>
                                            <div class="input-field">
                                                <input name="redirect_on_success" class="input" type="hidden" :value="redirectUrl">
                                            </div>
                                            <div :class="{'center-align':true,'hide':!beforeLogin ,'red':true,'white-text':true}">
                                                <p>{{this.message}}</p>
                                            </div>
                                            <p class="control center-align">
                                                <template v-if="message">
                                                    <button class="center-align btn waves-effect waves-light" @click.prevent="login">{{tr('Try Again')}}</button>
                                                    <button class="modal-action modal-close center-align btn waves-effect waves-light" >{{tr('Close')}}</button>
                                                </template>
                                                <template v-else="message">
                                                    <button class="center-align btn waves-effect waves-light" @click.prevent="login">{{tr('Login')}}</button>
                                                    <button class="center-align btn waves-effect waves-light" @click="closePage">{{tr('Close')}}</button>
                                                </template>
                                            </p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                    </div>
                </div>`
});